/******** header-breadcrumb ******/

.header-breadcrumb {
    padding: 140px 0;
     background-image: url(../images/sea-port-panorama-26909374.jpg);
    
     position: relative !important;
     background-size: cover !important;
     background-position: center !important;
     background-repeat: no-repeat !important;
     height: 100vh;
     width: 100%;
 }
 .header-breadcrumb-two {
     padding: 40px 0;
     min-height: 100%;
     position: relative !important;
     height: 100%;
     background: #FFF;
     width: 100%;
         border-top: 1px solid #f1f1f1;
 }
 .header-breadcrumb::after {
     background: rgba(50, 50, 50, 0.4);
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     content: "";
 }
 .header-breadcrumb .container {
     
   
     text-align: left;
 }
 
 .header-breadcrumb .page-title-heading {
         color: #fff;
     position: relative;
     text-transform: none;
     font-size: 30px;
     font-weight: 600;
     margin-bottom: 0px;
     line-height: 50px;
 }
 .headerText
 {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    z-index: 5;
    text-align: center;
padding:10px;
 }


 .header-breadcrumb .page-title-heading {
    color: #fff;
position: relative;
text-transform: none;
font-size: 50px;
font-family:'Roboto',sans-serif;
font-weight: 600;
margin-bottom: 0px;
line-height: 50px;
}
.page-title-line {
    color: #fff;
    display: block;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    letter-spacing: .20px;
    font-size: 28px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 15px;
    font-weight: 600;

    position: relative;
}
@media(max-width:538px) and (min-width:481px)
 {
    .header-breadcrumb {
        padding: 140px 0;
         background-image: url(../images/slide1.jpg);
        
         position: relative !important;
         background-size: cover !important;
         background-position: center !important;
         background-repeat: no-repeat !important;
         height: 100vh;
         width: 100%;
     }
     .header-breadcrumb-two {
         padding: 40px 0;
         min-height: 100%;
         position: relative !important;
         height: 100%;
         background: #FFF;
         width: 100%;
             border-top: 1px solid #f1f1f1;
     }
     .header-breadcrumb::after {
         background: rgba(50, 50, 50, 0.4);
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         content: "";
     }
     .header-breadcrumb .container {
         
       
         text-align: left;
     }
     
     /* .header-breadcrumb .page-title-heading {
             color: #fff;
         position: relative;
         text-transform: none;
         font-size: 30px;
         font-weight: 600;
         margin-bottom: 0px;
         line-height: 50px;
     } */
     .headerText
     {
        position:absolute;
        top:100px;
        left:10px;
        right:10px;
        bottom:0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        z-index: 5;
        text-align: center;
    padding:20px;
     }
    
    
     .header-breadcrumb .page-title-heading {
        color: #fff;
    position: relative;
    text-transform: none;
    font-size: 20px;
    font-family:'Roboto',sans-serif;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 30px;
    }
    .page-title-line {
        color: #fff;
        display: block;
        text-transform: capitalize;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        letter-spacing: .20px;
        font-size: 16px;
        line-height: 30px;
        margin: 0;
        margin-bottom: 15px;
        font-weight: 600;
    
        position: relative;
    }
    
 }

 @media(max-width:481px) and (min-width:320px)
 {
    .header-breadcrumb {
        padding: 140px 0;
         background-image: url(../images/slide1.jpg);
        
         position: relative !important;
         background-size: cover !important;
         background-position: center !important;
         background-repeat: no-repeat !important;
         height: 100vh;
         width: 100%;
     }
     .header-breadcrumb-two {
         padding: 40px 0;
         min-height: 100%;
         position: relative !important;
         height: 100%;
         background: #FFF;
         width: 100%;
             border-top: 1px solid #f1f1f1;
     }
     .header-breadcrumb::after {
         background: rgba(50, 50, 50, 0.4);
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         content: "";
     }
     .header-breadcrumb .container {
         
       
         text-align: left;
     }
     
     /* .header-breadcrumb .page-title-heading {
             color: #fff;
         position: relative;
         text-transform: none;
         font-size: 30px;
         font-weight: 600;
         margin-bottom: 0px;
         line-height: 50px;
     } */
     .headerText
     {
        position:absolute;
        top:100px;
        left:10px;
        right:10px;
        bottom:0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        z-index: 5;
        text-align: center;
    padding:20px;
     }
    
    
     .header-breadcrumb .page-title-heading {
        color: #fff;
    position: relative;
    text-transform: none;
    font-size: 20px;
    font-family:'Roboto',sans-serif;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 30px;
    }
    .page-title-line {
        color: #fff;
        display: block;
        text-transform: capitalize;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        letter-spacing: .20px;
        font-size: 16px;
        line-height: 30px;
        margin: 0;
        margin-bottom: 15px;
        font-weight: 600;
    
        position: relative;
    }
    
 }

 .header-breadcrumb .breadcrumb,
 .header-breadcrumb-two .breadcrumb {
     text-align: left;
     padding: 0;
     background: none;
     display: block;
     margin: 0;
     border: none;
     border-radius: 0;
     margin-top: 20px;
 }
 
 .header-breadcrumb .breadcrumb li,
 .header-breadcrumb-two .breadcrumb li {
     display: inline-block;
     position: relative;
     padding-right: 5px;
     padding-left: 5px;
     font-size: 16px;
     font-weight: 500;
     color: #eee;
     text-transform: capitalize;
     transition: 0.4s;
     letter-spacing: 0.20px;
     font-family: 'Roboto', sans-serif;
 }
 .header-breadcrumb .breadcrumb li a,
 .header-breadcrumb-two .breadcrumb li a{
     transition: 0.4s;
 }
 .header-breadcrumb .breadcrumb li:hover a,
 .header-breadcrumb-two .breadcrumb li:hover a {
     color: #ff6119;
 }
 .header-breadcrumb-two .breadcrumb li a {
         color: #666;
 }
 .header-breadcrumb-two .breadcrumb li {
         color: #989898;
 }
 .header-breadcrumb-two .breadcrumb {
         margin-top: 0;
 }
 .img-feat-two {
    background-image: url(../images/02.jpg);
    top: 0;
    left: 0;
    position: relative !important;
    background-size: cover !important;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    height: 100%;
    width: 100%;
    min-height: 318px;
}