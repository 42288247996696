/******** header-breadcrumb ******/

#page2 {
    padding: 140px 0;
     background-image: url(../images/service-banner.jpg);
     height:100vh;

     position: relative !important;
     background-size: cover !important;
     background-position: center !important;
     background-repeat: no-repeat !important;

     width: 100%;
  }
.content-box
{
   padding:30px;
   /* height:80vh; */
}
.content-box h4
{
   font-family: 'Roboto',sans-serif;
   font-weight:600;

}
.content-box p
{
   font-family: 'Poppins',sans-serif;
   font-size:16px;
   font-weight: 500;
}
.backBtn
{
   display:flex;
   justify-content: end;
   margin-top:30px;
}
.backBtn i
{
   font-size:30px;
   
}