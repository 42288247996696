@media (max-width: 350px) {
    .info-header h1 {
        font-size: 22px !important;
    }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    
/*========== Start Navbar ==========*/  
.d-n-mobile {
    display: none !important;
}
.main-nav-bar .my-logo {
        /* text-align: center; */
    margin-right: auto;
    /* margin-left: auto; */
    /* display: block; */
}
    
.main-nav-bar .nav-menu-bar {
    background: #FFF;
    border-top: 1px solid #eee;
    position: relative;
    width: 100%;
    display: block;
    height: 85px;
}
.main-nav-bar .nav-menu-bar .stuck {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 5555;
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
    border-bottom: none;
    background: #FFF;
    display: block;
    height: 85px;
}
.main-nav-bar .navbar-toggle {
    height: 32px;
    line-height: 32px;
    width: 30px;
    cursor: pointer;
    top: 35px;
    right: 15px;
    position: absolute;
    right: inherit;
    left: 15px;
}
.main-nav-bar .nav-menu-main {
    background: #fff;
    padding-top: 85px;
}
.main-nav-bar .icon-links {
    float: none;
    margin-left: 0;
    top: 0px;
    right: 15px;
    position: absolute;
}
.icon-links {
    float: none;
    margin-left: 0;
    top: 0px;
    right: 60px;
    position: absolute;
}

/*========== Start Navbar ==========*/   
.navbar-toggle {
    display: block;
}
/* .nav-menu {
    display: none;
} */
    
.nav-menu .nav-item .nav-link:before {
    display: none;
}
/*========== Start Navbar Fixed ==========*/ 
.fixed-top {
    top: 0;
    background: #FFF;
    position: fixed;
}
.fixed-top .nav-output {
    background: #FFF;
}
.fixed-top .my-logo .logo-two {
    display: block;
}
.fixed-top .my-logo .logo-one {
    display: none;
}
.fixed-top .nav-menu li a,
    .fixed-top .icon-links a {
    color: #323232;
}
.fixed-top,
.nav-fixed {
    padding-top: 10px;
}
.side-menu {
    width: 100%;
}  
.nav-bar-two .nav-menu {
    float: inherit;
    width: 100%;
}
.nav-bar-two .nav-menu li a {
    margin-left: 0;
    margin-right: 0;
}
/*========== Start Header ==========*/    
.info-header h1 {
    font-size: 25px;
    margin-bottom: 20px;
    line-height: 30px;
}
.text-header {
    text-transform: capitalize;
    font-size: 14px;
    color: #f8f8f8;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 30px;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
}
.info-header .play-video span {
    display: none;
}
.slider-hero .owl-nav {
    display: none;
}
.main-home {
    height: 450px;
}
.main-home,
.main-home .owl-item .info-header,
.main-home .owl-item {
    height: 450px !important;
}
.slider-hero .owl-dots {
    display: none;
}

.home-three,
.home-three .owl-item .info-header,
.home-three .owl-item {

        height: 100vh !important;
}
/* Home two */
.home-two .info-header .top-title-header {
    font-size: 14px;
    font-weight: 500;
}
.home-two {
    height: 600px;
}
.home-two .info-header {
    height: 600px;
}
.home-two .info-header h1 {
    margin-top: -30px;
}
/* Home Three  */
.home-classic,
.home-classic .owl-item .info-header,
.home-classic .owl-item {
    height: 450px !important;
}
.home-classic .inner-banner {
    padding: 30px;
}
.home-classic .top-title-header {
    font-size: 12px;
    font-weight: 600;
}
.home-classic .info-header h1 {
    line-height: 25px;
    font-size: 25px;
    margin-bottom: 20px;
}
.home-classic .text-header {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 25px;
}
.home-three .info-header h1 {
    margin-bottom: 30px;
    line-height: 40px;
}

.bar-get h2 {
    font-size: 20px;
    line-height: 30px;
}
.bar-get .btn-get-free .btn-one {
    float: left;
    margin-top: 30px;
}
    
/*========== Start About ==========*/
.section-title h2,
.section-title-left h2 {
    font-size: 14px;
}
.section-title h3,
.section-title-left h3 {
    font-size: 26px;
    line-height: 30px;

}
.p-title-section {
margin-top: 0px;
margin-left: 0px;
}

.call-us {
    margin-left: 10px;
}
.call-us span {
    font-size: 12px;
}
.call-us .phone-number {
    font-size: 14px;
    letter-spacing: 0px;
}
.feat-why-us {
    padding: 10px 10px 10px;
    border: 1px solid #ddd;
}
    
.info-about h5 {
        font-size: 18px;
    margin-top: -5px;
}
    
.about-img-two {
    position: relative;
    padding-left: 0;
    padding-right: 0;
}
.about-img-two .overlay-about {

    display: none;
}
.padding-about-one {
    margin-bottom: 0;
}
.about-img-two .overlay-about {
    color: #fff;
    position: relative;
    padding: 30px 20px;
}
.slider-about::before {
    display: none;
}
.about-img-three {

    border: none;
    padding: 0;
    margin: 0;
}
.case-three .text-of-case {
    position: relative;
    bottom: inherit;
    right: inherit;
    left: inherit;
    text-align: left;
    background: #FFF;
    padding: 30px;
    border-bottom: 3px solid #ff6119;
    width: 100%;
}
.case-three .case-study-text {
    position: relative;
    left: inherit;
    bottom: inherit;
    right: inherit;
    top: inherit;
    z-index: 25;
    opacity: 0;
    display: block;
}
.bar-get .content-get-bar {

    padding: 70px 0px;

}
.bar-get .main-btn-four {
    float: left;
    margin-top: 25px;
}
.team-carousel .owl-nav {
    top: 40%;
}
.team-carousel .owl-nav div.owl-prev {
    right: inherit;
    left: 0;
}
.testimonial .owl-nav {
    display: none;
}
.services-carousel .owl-nav {
    top: 30%;
}
.services-carousel .owl-nav div.owl-prev {
    right: inherit;
    left: 0;
}
.content-statistics {
        padding: 70px 15px;
}
.content-testimonial {
    padding: 70px 15px;
}  
.testimonials-one .owl-nav {
    position: absolute;
    bottom: inherit;
    right: 40px;
    z-index: 999;
    transform: translateY(-50%);
    top: -15px;
}
.join-team {
    padding: 50px 15px 10px;
}   
.join-team .btn-work-with-us .main-btn-two {
    margin-bottom: 40px !important;
    float: left;
    margin-top: 25px;
}
    

.img-video {
    position: relative;
    margin-bottom: 50px;
}
.apply-team {
    padding: 100px 10px 100px;
}
.box-single-product {
    padding: 100px 10px 50px;
}
.quantity {
    float: none;
}
.product-info .btn-one {
    float: none;
    margin-top: 30px;
}

.share-social li {
    padding-left: 0;
    margin-right: 10px;
}
   
.nav-tabs li {
    width: 155px;
}
.content-revision, .my-account,
    .lost-password-page,
    .cart-empty-item{
    padding: 50px 25px;
}

.single-item-one,
.single-item-two,
.single-item-three {
        padding: 70px 10px 20px;
}
.support .centent-support {
    padding: 100px 10px 70px;
}
   

.scroll-up {
    bottom: 90px;
    right: 10px;
}

    
.portoflio-one .owl-nav {
    top: 32%;
}
.portoflio-one .owl-nav div.owl-prev {
    right: inherit;
    left: 0;
}
.case-three .owl-nav div.owl-next,
.case-single .owl-nav div.owl-next {
    right: 0;
    position: absolute;
    transition: all 0.4s ease 0s;
}
/* Pages */
.header-breadcrumb {
    padding: 100px 0;
}
.header-breadcrumb .page-title-heading {
    color: #fff;
    position: relative;
    text-transform: capitalize;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 40px;
}
    
.join-team h4 {
    margin-bottom: 0;
}

.single-blog .blog-info .date li {
    padding-right: 10px;
}
.single-blog .author img {
    margin-right: 5px;
}
.single-blog .share span {
    padding-right: 5px;
}
.single-blog .share-social li {
    padding-left: 5px;
}

.categories-list li a {
    padding-left: 20px;
}
.comment-author img {
    margin-right: 10px;
    width: 60px;
    height: 60px;
}
.person {
    padding-left: 70px;
}
    
.reply {
    margin-left: 0px;
}
.error-item h1 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 5px;
}
.error-item h2 {
    font-size: 18px;
}
    .box-project .share-product {
        margin-bottom: 40px;
}
.row-contact .no-padding {
    border-right: none;
} 

.row-contact .single-item {
    position: relative;
    padding-left: 50px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-top: 30px;
}
.row-contact .no-padding:first-child .single-item {
    margin-top: 40px;
}
.row-contact .no-padding:last-child .single-item {
    margin-bottom: 40px;
}
footer .copyright {
    text-align: center;
}
.links-of-footer {
    font-size: 14px;
    color: #f4f7fd;
    letter-spacing: 1.7px;
    line-height: 30px;
    padding-top: 30px;
    margin-bottom: 0;
    text-align: center;
    margin-top: -15px;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 20px;
}
.links-of-footer li {
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
}
}



/* Small devices (landscape phones, less than 768px) */
@media (min-width: 576px) and (max-width: 767.98px) { 
.container {
    max-width: 100%;
}
    
 /*========== Start Navbar ==========*/  
.d-n-tab {
    display: none;
}
    

.nav-bar .single-item {
    padding-left: 40px;
}  
.main-nav-bar .nav-menu-bar {
    background: #FFF;
    border-top: 1px solid #eee;
    position: relative;
    width: 100%;
    display: block;
    height: 85px;
}
.main-nav-bar .nav-menu-bar .stuck {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 5555;
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
    border-bottom: none;
    background: #FFF;
    display: block;
    height: 85px;
}
.main-nav-bar .navbar-toggle {
    height: 32px;
    line-height: 32px;
    width: 30px;
    cursor: pointer;
    top: 35px;
    right: 15px;
    position: absolute;
    right: inherit;
    left: 15px;
}
.main-nav-bar .nav-menu-main {
    background: #fff;
    padding-top: 85px;
}
.main-nav-bar .icon-links {
    float: none;
    margin-left: 0;
    top: 0px;
    right: 15px;
    position: absolute;
}
.upper-bar .social-media-bar {
    display: none;
}
.icon-links {
    float: none;
    margin-left: 0;
    top: 0px;
    right: 60px;
    position: absolute;
}

    
/*========== Start Navbar Fixed ==========*/   
.fixed-top {
    top: 0;
    background: #FFF;
    position: fixed;
}
.fixed-top .nav-output {
    background: #FFF;
}
.fixed-top .my-logo .logo-two {
    display: block;
}
.fixed-top .my-logo .logo-one {
    display: none;
}
.fixed-top .nav-menu li a,
    .fixed-top .icon-links a {
    color: #323232;
}
.fixed-top,
.nav-fixed {
    padding-top: 10px;
}
    
/*========== Start Navbar ==========*/   
.navbar-toggle {
    display: block;
}
.nav-menu {
    display: none;
}
.nav-menu .nav-item .nav-link:before {
    display: none;
}
   
.nav-bar-two .nav-menu {
    float: inherit;
    width: 100%;
}
.nav-bar-two .nav-menu li a {
    margin-left: 0;
    margin-right: 0;
}
/*========== Start Header ==========*/    
.info-header h1 {
    font-size: 50px;
    line-height: 55px
}
.main-home,
.main-home .owl-item,
.main-home .owl-item .info-heade {
    height: 500px !important;
}


/* Home Three  */
.home-classic,
.home-classic .owl-item .info-header,
.home-classic .owl-item {
    height: 500px !important;
}
.info-header {
    height: 500px;
    min-height: 100%;
}
.home-classic .info-header h1 {
    line-height: 45px;
    font-size: 35px;
}

    
.slider-hero .owl-nav {
    display: none;
}
.slider-hero .owl-dots {
    display: none;
}
.info-header h1 {
    line-height: 50px;
    font-size: 40px;
}
.home-three,
.home-three .owl-item .info-header,
.home-three .owl-item {

        height: 100vh !important;
}
.home-three .info-header h1 {
    margin-bottom: 30px;
    line-height: 40px;
    font-size: 30px;
}
.text-header {
    font-size: 16px;
    line-height: 26px;
}

.bar-get h2 {
    font-size: 26px;
    line-height: 35px;
}
.bar-get .btn-get-free .btn-one {
    float: left;
    margin-top: 30px;
    margin-bottom: 10px;
}
.bar-get .content-get-bar {

    padding: 70px 0px;

}
.bar-get .main-btn-four {
    float: left;
    margin-top: 25px;
}
   
.home-two {
    height: 600px;
}
.home-two .info-header {
    height: 600px;
}
.home-two .info-header h1 {
    margin-top: -30px;
}
   
/*========== Start About ==========*/
.section-title h3,
.section-title-left h3 {
    font-size: 30px;
    line-height: 35px;

}
.section-title h2,
.section-title-left h2 {
    font-size: 14px;
}
.p-title-section {
    margin-top: 0;
    margin-left: 0;
}
.feat-why-us {
    padding: 10px 15px 10px;

}      
.about-img-two {
    position: relative;
    padding-left: 0;
    padding-right: 0;
}

.padding-about-one {
    margin-bottom: 0;
}

.team-carousel .owl-nav {
    top: 40%;
}
.team-carousel .owl-nav div.owl-prev {
    right: inherit;
    left: 0;
}
.testimonial .owl-nav {
    display: none;
}
.img-video {
    position: relative;
    margin-bottom: 50px;
}
.services-carousel .owl-nav {
    top: 20%;
}   
.services-carousel .owl-nav div.owl-prev {
    right: inherit;
    left: 0;
}
.portoflio-one .owl-nav {
    top: 35%;
}
.portoflio-one .owl-nav div.owl-prev {
    right: inherit;
    left: 0;
}
.join-team .btn-work-with-us .main-btn-two {
    margin-bottom: 40px !important;
    float: left;
    margin-top: 25px;
}
.apply-team {
    padding: 100px 20px 100px;
}
.box-single-product {
    padding: 100px 20px 50px;
}
.content-revision, .my-account,
    .lost-password-page,
    .cart-empty-item{
    padding: 50px 35px;
}
.single-item-one,
.single-item-two,
.single-item-three {
    padding-right: 20px;
    padding-left: 20px;
}
.support .centent-support {
    padding: 100px 20px 70px;
}

.scroll-up {
    bottom: 90px;
    right: 10px;
}
.join-team h4 {
    margin-bottom: 0;
}
.error-item h1 {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 10px;
}
.error-item h2 {
    font-size: 19px;
}
.box-project .share-product {
        margin-bottom: 40px;
}
.content-statistics {
    padding: 70px 25px;
}
.text-support {
    padding-right: 0;
}
.row-contact .no-padding {
    border-right: none;
} 

.row-contact .single-item {
    position: relative;
    padding-left: 50px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-top: 30px;
}

.row-contact .no-padding:last-child .single-item {
    margin-bottom: 40px;
}
.row-contact .no-padding:last-child {
    border-right: none;
    border-top: 1px solid #ddd;
}
.row-contact .no-padding:last-child .single-item {
    margin-bottom: 40px;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
}
.row-contact .no-padding:first-child {
    border-right: 1px solid #DDD;
}
footer .copyright {
    text-align: center;
}
.links-of-footer {
    font-size: 14px;
    color: #f4f7fd;
    letter-spacing: 1.7px;
    line-height: 30px;
    padding-top: 30px;
    margin-bottom: 0;
    text-align: center;
    margin-top: -15px;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 20px;
}
.links-of-footer li {
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
}
    
.price .price-item {
        padding: 50px 30px;
}
.price-item .features-price ul {
    padding: 50px 0 15px 0px;
}

}

/*  Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) { 
.container {
    max-width: 100%;
}
/*========== Start Navbar ==========*/   
/*========== Start Navbar ==========*/ 
.d-n-md {
    display: none;
}
    

.nav-bar .single-item {
    padding-left: 40px;
}  
.main-nav-bar .nav-menu-bar {
    background: #FFF;
    border-top: 1px solid #eee;
    position: relative;
    width: 100%;
    display: block;
    height: 85px;
}
.main-nav-bar .nav-menu-bar .stuck {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 5555;
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
    border-bottom: none;
    background: #FFF;
    display: block;
    height: 85px;
}
.main-nav-bar .navbar-toggle {
    height: 32px;
    line-height: 32px;
    width: 30px;
    cursor: pointer;
    top: 35px;
    right: 15px;
    position: absolute;
    right: inherit;
    left: 15px;
}
.main-nav-bar .nav-menu-main {
    background: #fff;
    padding-top: 85px;
}
.main-nav-bar .icon-links {
    float: none;
    margin-left: 0;
    top: 0px;
    right: 15px;
    position: absolute;
}
    
.nav-bar-two .nav-menu {
    float: inherit;
    width: 100%;
}
.nav-bar-two .nav-menu li a {
    margin-left: 0;
    margin-right: 0;
}
.upper-bar-two {
    background: #FFF;
    border-bottom: 1px solid #f1f1f1;
}
.upper-bar-two .contact-us-bar a {
    color: #323232;
    font-weight: 500;
}
.upper-bar-two .social-media-bar li a {
    transition: .4s;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background: #f5f5fb;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #323232;
    display: block;
    border: 1px solid #f1f1f1;
}
.upper-bar-two .contact-us-bar a span {
    color: #ff6119;
}
/*========== Start Navbar Fixed ==========*/   
.fixed-top {
    top: 50px;
    background: #FFF;
    position: absolute;
}
.fixed-top .nav-output {
    background: #FFF;
}
.fixed-top .my-logo .logo-two {
    display: block;
}
.fixed-top .my-logo .logo-one {
    display: none;
}
.fixed-top .nav-menu li a,
    .fixed-top .icon-links a {
    color: #323232;
}
.fixed-top,
.nav-fixed {
    padding-top: 10px;
}
    
.icon-links {
    float: none;
    margin-left: 0;
    top: 0px;
    right: 60px;
    position: absolute;
}

.nav-menu .nav-item .nav-link:before {
    display: none;
}
/*========== Start Header ==========*/    
.info-header h1 {
    font-size: 45px;
    line-height: 50px
}
.home-classic .info-header h1 {
    font-size: 40px;
    line-height: 55px;
}
.home-classic .text-header {
    margin-left: 0;
}

.section-title p {
    padding-right: 0;
    padding-left: 0;
}
.home-three .info-header h1 {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 50px;
}
/*========== Start About ==========*/
.section-title h3,
.section-title-left h3 {
    font-size: 32px;

}
.section-title h2,
.section-title-left h2 {
    font-size: 14px;
}
.section-title p {
    padding-right: 0;
    padding-left: 0;
    margin-top: -10px;
    margin-left: 0;
}
.about-img-two {
     padding-left: 0;
    padding-right: 0;
}

.feat-why-us {
    padding: 10px 10px 10px;
    border: 1px solid #ddd;
}
.img-video {
    position: relative;
    margin-bottom: 50px;
}

.our-video-text {
    padding-top: 0;
}
.box-single-product {
    padding: 100px 20px 50px;
}
.apply-team {
    padding: 100px 20px 100px;
}
.content-revision, .my-account,
    .lost-password-page,
    .cart-empty-item{
    padding: 50px 35px;
}
.single-item-one,
.single-item-two,
.single-item-three {
    padding-right: 20px;
    padding-left: 20px;
}
.support .centent-support {
    padding: 100px 20px 70px;
}
    
.scroll-up {
    bottom: 90px;
    right: 10px;
}
.box-project .share-product {
        margin-bottom: 40px;
}
.row-contact .no-padding {
    border-right: none;
} 

.row-contact .single-item {
    position: relative;
    padding-left: 50px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-top: 30px;
}

.row-contact .no-padding:last-child .single-item {
    margin-bottom: 40px;
}
.row-contact .no-padding:last-child {
    border-right: none;
    border-top: 1px solid #ddd;
}
.row-contact .no-padding:last-child .single-item {
    margin-bottom: 40px;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
}
.row-contact .no-padding:first-child {
    border-right: 1px solid #DDD;
}
.text-support {
    padding-right: 0px;
}
.bar-get h2 {
    font-size: 34px;
    line-height: 35px;
}
.bar-get .btn-get-free .btn-one {
    float: left;
    margin-top: 30px;
    margin-bottom: 10px;
}
.bar-get .content-get-bar {

    padding: 70px 0px;

}
.bar-get .main-btn-four {
    float: left;
    margin-top: 25px;
}
}
@media (max-width: 992px)
{
/*========== Start Navbar ==========*/   
.navbar-toggle {
    display: block;
}
.left-side-bar {
    max-width: 100%;
}
.blog-pagination {
    margin-top: 0px;
    margin-bottom: 50px;
}
.nav-bar-three.fixed-top-one .logo-one {
    display: none;
}
.nav-bar-three.fixed-top-one .my-logo {
    display: inline-block;
    width: 130px;
    padding: 24.5px 0;
    padding-bottom: 17px;
}
.nav-bar-three.fixed-top-one .logo-two {
    display: block;
}
.nav-bar-three .stuck {
    background: #FFF;
}
    .nav-bar-three .icon-links .btn-three {
            background: #ff6119;
            color: #fff;
    }
.nav-bar-three .navbar-toggle span {
    background: #02185a;
}
.nav-bar-three .side-menu-btn span {
    background-color: #02185a;
}
.nav-bar-fixed .nav-menu {
    float: none;
    width: 100%;
}
.nav-bar-three .nav-menu li a {
    margin-left: 0;
    margin-right: 0;
    color: #02185a;
}
.nav-menu {
    /* display: none; */
    float: none;
    max-height: 550px;
    overflow: scroll;
}
.nav-menu li {
    display: block;
}
.nav-link {
    padding: 10px 0; 
    margin-left: 0;
    margin-right: 10px;
    /* border-bottom: 1px solid #EEE; */
}
.has-dropdown .icon-down {
    display: block;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    background: transparent;
    z-index: 555;
    cursor: pointer;
    font-size: 15px;
    line-height: 46px;
}
.nav-bar .nav-menu .nav-item.has-dropdown > ul.sub-menu {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
    -ms-transform: translateY(0%);
    position: relative;
    top: 0;
    width: 100%;
    opacity: 1;
    visibility: visible;
    display: none;
    margin: 0;
    transition: none;
}
    
.nav-bar .sub-menu li.has-dropdown-two > ul.sub-menu-two {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%);
    position: relative;
    width: 100%;
    opacity: 1;
    top: 0;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 3px solid #ff6119;
    margin-left: 0px;
    display: none;
    margin: 0;
    transition: none;
}
    .post-comment {
        margin-bottom: 50px;
    }
    .img-widget img {
        width: 100%;
    }
    .more-projects .portoflio-one .owl-nav div.owl-prev {
    left: inherit;
    right: 50px;
}
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 993px)  { 
.container {
    max-width: 100%;
}    
.nav-menu {
    display: block !important;
    
}

.nav-item.has-dropdown:hover ul.sub-menu,
.nav-bar .sub-menu .has-dropdown-two:hover > ul.sub-menu-two {
    transform: translateY(0) !important;
    -webkit-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    -o-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    opacity: 1 !important;
    visibility: visible !important;
    display: block !important;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
 
}
}
@media (min-width: 993px) and (max-width: 1170px) {
.nav-bar-three .nav-menu li a {
    margin-left: 17px;

}
}
@media (min-width: 1200px) {
.container {
    max-width: 1170px;
}

}