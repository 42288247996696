/*----------------------------------------------------------------
                        Start Footer
----------------------------------------------------------------*/
/* footer {
    padding-top: 80px;
    background: #484545;
    position: relative;
    

} */
.footer .main-btn-three
{
    margin-left:10px;
}
footer {
    /* background-image:linear-gradient(to right, rgba(41, 40, 40, 0.6), rgba(42, 40, 40, 0.6)),  url(../images/map_img.png);
    background-position: center center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%; */
    background:whitesmoke;
    /* position: absolute; */
    /* opacity: 0.2; */
    content: "";
    z-index: 0;
    height: 100%;
}
.footer-default-padding {
    padding: 80px  80px 0;
}z
.footer-widget h4 {
    position: relative;
    font-size: 19px;
    font-weight: 600;
    text-transform: capitalize;
    color:#02185a;
    letter-spacing: .5px;
    padding-top:15px;
    padding-left:57px;
    
    
}
.footer-widget h3{
    /* position: relative; */
    font-size: 19px;
    font-weight: 600;
    text-transform: capitalize;
    color:#02185a;
    letter-spacing: .5px;
    padding-top:15px;
    /* padding-left:57px; */
    text-align: center;
}
@media(max-width:538px) and (min-width:300px){
    .footer-default-padding {
        padding: 20px;
    }
    .footer-widget h4 {
        position: relative;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        color:#02185a;
        letter-spacing: .5px;
        padding-left:0;
    }
}

.footer-widget .line-footer {
    /* width: 50px;
    height: 3px;
    background: #ff6119; */
    margin-bottom: 30px;
    margin-top: 20px;
}
/* .footer-row {
    margin-bottom: 50px;
} */


.footer-widget .footer-logo img {
    width: 180px;
    margin-bottom: 30px;
    margin-top: -25px;
}
.contact-info {
    padding-left: 0;
}
.contact-info .single-contact {
    position: relative;
    padding-left: 40px;
    margin-bottom: 30px;
}

.contact-info .single-contact span  {
    position: absolute;
    left: 0;
    color: rgba(255, 255, 255, 0.85);
    top: 0;
    font-size: 16px;
}
.contact-info .single-contact .info-cont p {
    color: #ccc;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 15px;
    position: relative;
}



.footer-widget .social-media-footer {
    padding-left: 0;
    margin-bottom: 0;
}
.footer-widget .social-media-footer li {
    display: inline-block;
    padding-right: 15px;
}
.footer-widget .social-media-footer li a {
    font-size: 18px;
    color: #fff;
    transition: .1s;
    
}
.footer-widget .social-media-footer li a:hover {
    color: #f57479;
}
/* COMPANY LINKs */
.footer-widget .footer-link {
    padding-left: 0;
    
}
.footer-widget .footer-link li {
    border-bottom: 1px solid rgba(214, 214, 214, 0.1);
    transition: .4s;
    list-style: none;
    
}
.footer-widget .footer-link li:first-child a {
    padding-top: 0;
    font-size: 17px;
}
.footer-widget .footer-link li:hover a {
    padding-left: 8px;
    color:#000;
}
.footer-widget .footer-link li a {
    font-size: 17px;
    color: #02185a;
    display: block;
    transition: .4s;
    padding-top: 13px;
    padding-bottom: 4px;
    line-height: 14px;
    font-weight: 600;
    text-transform: capitalize;

}

.footer-widget h4{
    padding-left: 130px;
}

.footer-widget .footer-link li span {
    padding-right: 5px;
    font-size: 20px;
    font-weight:800;
    
}
.row-contact {
    /* background: #f9f9f9; */
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    /* border: 1px solid #ddd; */
    position: relative;
}
/* .row-contact .no-padding {
    border-right: 1px solid #DDD;
} */
.row-contact .no-padding:last-child {
    border-right: none;
}
.row-contact .single-item {
    position: relative;
    padding-left: 60px;
    padding-right:20px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-top: 40px;
}
.row-contact .single-item i {
    left: 0;
    top: 0;
    color: #fff;
    position: absolute;
    font-size: 30px;
}
.row-contact .single-item i::before {
    margin: 0;
    font-size: 35px;
    line-height: 35px;
}
.row-contact .single-item p {
    font-size: 16px;
    color: #fff;
    /* font-weight: 500; */
    line-height: 15px;
    margin-bottom: 10px;
}
/*  newsletter-item */

.footer-widget p {
    font-weight: 500;
    color: #02185a;
    font-size: 15px;
    margin-right: 70px;
   
}
.newsletter-item {
    position: relative;
    margin-bottom: 35px;
}
/* .newsletter-item input {
    
} */
.newsletter-item button {
    height: 50px;
    box-shadow: none;
    position: absolute;
    cursor: pointer;
    right: 0px;
    width: 50px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    background: #ff6119;
    display: inline-block;
    top: 0;
    line-height: 50px;
    border: 1px solid #ff6119;
}

.footer-bar {
    z-index: 3;
    position: relative;
    background: #fff;
    padding:20px 0;
}

footer .copyright {
    font-weight: 500;
    font-size: 10px;
    color: #02185a;
    letter-spacing: 1.7px;
    padding:20px;
    text-align: left;
}
footer .copyright a {
    color: #02185a;
    font-weight: 700;
}

.links-of-footer {
        text-align: right;
    padding-left: 0;
    font-size: 14px;
    color: #f4f7fd;
    letter-spacing: 1.7px;
    line-height: 30px;
    padding:10px;
}

.links-of-footer li {
    display: inline-block;
    margin-left: 25px;
}
.links-of-footer li a {
    position: relative;
    font-weight: 500;
    letter-spacing: 0.7px;
    line-height: 30px;
    text-transform: capitalize;  
    transition: 0.4s;
}
.links-of-footer li a::before {
    content: "";
    position: absolute;
    height: 5px;
    width: 5px;
    background: #f4f7fd;
    display: block;
    left: -16px;
    top: 8px;
    border-radius: 50%;
}
.links-of-footer li a:hover {
    color: #ff6119;
}
.links-of-footer li:first-child a::before {
    display: none;
}
/* Scroll Top */
.scroll-up {
    position: fixed;
    bottom: 70px;
    right: 20px;
    background: #ff6119;
    color: #FFF;
    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 18px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    z-index: 555;
    display: none;
    -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.18);
    -o-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.18);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.18);
    transition: all 0.4s; 
}

.footlink{
    padding-left: 115px;
} 

.scroll-up:hover {
    background: #02185a;

}
#map
{
    width:280px;
    height:140px;
}
@media(max-width:836px) and (min-width:538px)
{
    #map
    {
        width:300px;
        height:200px;
    }

    .footer-widget h4 {
        padding-left: 12px;
    }
}
@media(max-width:538px) and (min-width:320px)
{
    #map
    {
        width:280px;
        height:200px;
    }
    .footer-widget h4 {
        padding-left: 12px;
    }
}
#map2
{
    width:420px;
    height:450px;
}
@media(max-width:836px) and (min-width:538px)
{
    #map2
    {
        width:300px;
        height:200px;
    }
    .footer-widget h4 {
        padding-left: 12px;
    }
}
@media(max-width:538px) and (min-width:320px)
{
    #map2
    {
        width:280px;
        height:200px;
    }
    .footer-widget h4 {
        padding-left: 12px;
    }
}