.contact-us-page {
    background: #fff;
}
#page4 {
    padding: 140px 0;
     background-image: url(../images/contact.jpg);
    
     position: relative !important;
     background-size: cover !important;
     background-position: center !important;
     background-repeat: no-repeat !important;
     height: 100vh;
     width: 100%;
 }

.contact-us-meta .line-contact {
    margin-top: 45px;
    margin-bottom: 80px;
    height: 1px;
    background: #e6e4e4;
    width: 100%;
    
}
.contact-item {
        margin-bottom: 30px;
    background: #ffffff;
    padding: 30px 20px;
    border: 1px solid #000;
    border-radius: 2px;
    height:180px;
    min-height:100%;
}
.contact-item  .inner-contact {
    position: relative;
    padding-left: 50px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e6e4e4;
    padding-bottom: 25px;
}
.contact-item  .inner-contact span {
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    /* background: #ff6119; */
    border: 1px solid #02185a;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #000;
    border-radius: 2px;
}
.contact-item  .inner-contact h4 {
    font-size: 18px;
    line-height: 18px;
    padding-top: 10px;
    font-weight: 600;
    color: #02185a;
    padding-bottom: 10px;
    text-transform: capitalize;
}
.contact-us-meta .contact-item p {
    color: #666;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 15px;
    position: relative;
}

.img-contact {
    position: relative;
    
}
.img-contact img {
    width: 100%;
    
}
.img-contact .overlay-contact-us {
    padding: 10px;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.img-contact .text-overlay {
    width: 100%;
    height: 100%;
    border: 2px solid rgba(255, 255, 255, .9);
    background-color: rgba(25, 25, 33, 0.10);
    position: relative;
}

.img-contact .text-overlay h3 {
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 30px;
    color: #FFF;
    font-weight: 700;
}