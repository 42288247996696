/******** header-breadcrumb ******/

#page3 {
  padding: 140px 0;
   background-image: url(../images/banner\ \(3\).jpg);
   height:100vh;
   /* min-height: 100%; */
   position: relative !important;
   background-size: cover !important;
   background-position: center !important;
   background-repeat: no-repeat !important;
   /* height: 100%; */
   width: 100%;
}
.swiper-pagination {
	position: absolute;
	/* top: 200px; */
  bottom:60px;
	right: 100px;
  background:#fff;
  border-radius: 5px;;
	width: auto !important;
	left: auto !important;
	margin: 0;
}
.swiper-pagination-bullet {
	padding: 5px 5px;
	border-radius:0;
	width: auto;
	height: 30px;
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	color:#000;
	opacity: 1;
	background: rgba(0,0,0,0.2);
}
.swiper-pagination-bullet-active {
	color:#fff;
	background: #007aff;
}
.shell{
    padding:80px 0;
  }
  .wsk-cp-product{
    background:#fff;
    /* padding:15px; */
    border-radius:6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position:relative;
    margin:20px auto;
    height:250px;
  }
  .wsk-cp-img{
    position:absolute;
    top:5px;
    left:50%;
    transform:translate(-50%);
    -webkit-transform:translate(-50%);
    -ms-transform:translate(-50%);
    -moz-transform:translate(-50%);
    -o-transform:translate(-50%);
    -khtml-transform:translate(-50%);
    width: 100%;
    /* padding: 15px; */
    height:250px;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-img img{
    width:100%;
    transition: all 0.2s ease-in-out;
    border-radius:6px;
    height:100%
  }
  /* .wsk-cp-product:hover .wsk-cp-img{
    top:-40px;
  } */
  /* .wsk-cp-product:hover .wsk-cp-img img{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  } */
  .wsk-cp-text{
    padding:10px 0 40px 0;
  }
  .wsk-cp-text .category{
    text-align:center;
    font-size:12px;
    font-weight:bold;
    padding:5px;
    margin-bottom:45px;
    position:relative;
    transition: all 0.2s ease-in-out;
  }
  /* .wsk-cp-text .category > *{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -khtml-transform: translate(-50%,-50%);
      
  } */
  .wsk-cp-text .category > span{
    padding: 12px 30px;
    border: 1px solid #313131;
 
    background:#fff;
    color:#313131;
    box-shadow: 0 15px 28px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.10);
    border-radius:27px;
    transition: all 0.05s ease-in-out;
    width:150px;
    height:50px;
    
  }
  .wsk-cp-product:hover .wsk-cp-text .category > span{
    border-color:#ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product:hover .wsk-cp-text .category{
    margin-top: 0px;
  }
  .wsk-cp-text .title-product{
    text-align:center;
    
  }
  .wsk-cp-text .title-product a{
    text-align:center;
    text-decoration: none;
    color:#000;
    font-size:16px;
    font-weight: 500;
    text-transform: capitalize;
    padding:10px;
    border: 1px solid black;
    
  }
  .wsk-cp-text .title-product h3{
    font-size:18px;
    font-weight:bold;
    margin:15px auto;
    overflow: hidden;
      white-space: break-spaces;
      /* text-overflow: ellipsis; */
    width:100%;
  }
  .wsk-cp-text .description-prod p{
    margin:0;
  }
  /* Truncate */
  .wsk-cp-text .description-prod {
    text-align:center;
    width: 100%;
    height:62px;
    overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    margin-bottom:15px;
  }
  .card-footer{
    padding: 25px 0 5px;
    border-top: 1px solid #ddd;
  }
  .card-footer:after, .card-footer:before{
    content:'';
    display:table;
  }
  .card-footer:after{
    clear:both;
  }
  
  .card-footer .wcf-left{
    float:left;
    
  }
  
  .card-footer .wcf-right{
    float:right;
  }
  
  .price{
    font-size:18px;
    font-weight:bold;
  }
  
  a.buy-btn{
    display:block;
    color:#212121;
    text-align:center;
    font-size: 18px;
    width:35px;
    height:35px;
    line-height:35px;
    border-radius:50%;
    border:1px solid #212121;
    transition: all 0.2s ease-in-out;
  }
  a.buy-btn:hover , a.buy-btn:active, a.buy-btn:focus{
    border-color: #FF9800;
    background: #FF9800;
    color: #fff;
    text-decoration:none;
  }
  .wsk-btn{
    display:inline-block;
    color:#212121;
    text-align:center;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    border-color: #FF9800;
    background: #FF9800;
    padding:12px 30px;
    border-radius:27px;
    margin: 0 5px;
  }
  .wsk-btn:hover, .wsk-btn:focus, .wsk-btn:active{
    text-decoration:none;
    color:#fff;
  }  
  .red{
    color:#F44336;
    font-size:22px;
    display:inline-block;
    margin: 0 5px;
  }

  @media(max-width:1024px) and (min-widh:750px){
    .wsk-cp-product{
      background:#fff;
      /* padding:15px; */
      border-radius:6px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      position:relative;
      margin:20px auto;
      height:390px;
    }
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      /* padding: 15px; */
      height:250px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    } .wsk-cp-product{
    background:#fff;
    /* padding:15px; */
    border-radius:6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position:relative;
    margin:20px auto;
    height:390px;
  }
  .wsk-cp-img{
    position:absolute;
    top:5px;
    left:50%;
    transform:translate(-50%);
    -webkit-transform:translate(-50%);
    -ms-transform:translate(-50%);
    -moz-transform:translate(-50%);
    -o-transform:translate(-50%);
    -khtml-transform:translate(-50%);
    width: 100%;
    /* padding: 15px; */
    height:250px;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-img img{
    width:100%;
    transition: all 0.2s ease-in-out;
    border-radius:6px;
    height:100%
  }
  }
  @media(max-width:751px) and (min-width:620px){
    .wsk-cp-product{
      background:#fff;
      /* padding:15px; */
      border-radius:6px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      position:relative;
      margin:20px auto;
      height:390px;
    }
    .wsk-cp-text{
      padding-top:70%;
    }
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      /* padding: 15px; */
      height:300px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    } .wsk-cp-product{
    background:#fff;
    /* padding:15px; */
    border-radius:6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position:relative;
    margin:20px auto;
    height:390px;
  }
  .wsk-cp-img{
    position:absolute;
    top:5px;
    left:50%;
    transform:translate(-50%);
    -webkit-transform:translate(-50%);
    -ms-transform:translate(-50%);
    -moz-transform:translate(-50%);
    -o-transform:translate(-50%);
    -khtml-transform:translate(-50%);
    width: 100%;
    /* padding: 15px; */
    height:250px;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-img img{
    width:100%;
    transition: all 0.2s ease-in-out;
    border-radius:6px;
    height:100%
  }
  }
  @media(max-width:621px) and (min-width:538px){
    .wsk-cp-product{
      background:#fff;
      /* padding:15px; */
      border-radius:6px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      position:relative;
      margin:20px auto;
      height:390px;
    }
    .wsk-cp-text{
      padding-top:70%;
    }
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      /* padding: 15px; */
      height:300px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    } .wsk-cp-product{
    background:#fff;
    /* padding:15px; */
    border-radius:6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position:relative;
    margin:20px auto;
    height:390px;
  }
  .wsk-cp-img{
    position:absolute;
    top:5px;
    left:50%;
    transform:translate(-50%);
    -webkit-transform:translate(-50%);
    -ms-transform:translate(-50%);
    -moz-transform:translate(-50%);
    -o-transform:translate(-50%);
    -khtml-transform:translate(-50%);
    width: 100%;
    /* padding: 15px; */
    height:250px;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-img img{
    width:100%;
    transition: all 0.2s ease-in-out;
    border-radius:6px;
    height:100%
  }
  }
  @media(max-width:538px) and (min-width:300px){
    .wsk-cp-product{
      background:#fff;
      /* padding:15px; */
      border-radius:6px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      position:relative;
      margin:20px auto;
      height:350px;
    }
    .wsk-cp-text{
      padding-top:70%;
      padding-bottom:30px;
    }
    .wsk-cp-img{
      position:absolute;
      top:5px;
      left:50%;
      transform:translate(-50%);
      -webkit-transform:translate(-50%);
      -ms-transform:translate(-50%);
      -moz-transform:translate(-50%);
      -o-transform:translate(-50%);
      -khtml-transform:translate(-50%);
      width: 100%;
      /* padding: 15px; */
      height:270px;
      transition: all 0.2s ease-in-out;
    }
    .wsk-cp-img img{
      width:100%;
      transition: all 0.2s ease-in-out;
      border-radius:6px;
      height:100%
    } .wsk-cp-product{
    background:#fff;
    /* padding:15px; */
    border-radius:6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position:relative;
    margin:20px auto;
    height:auto;
  }

  @media (max-width: 390px) and (min-width: 30px) {
    .wsk-cp-text .title-product h3 {
     margin-top: 70px;
    }
  }
  
  /* .wsk-cp-img img{
    width:100%;
    transition: all 0.2s ease-in-out;
    border-radius:6px;
    height:100%
  } */
  }
  .search-box
  {
    display:flex;
    /* justify-content: center;
    align-items: center; */
    /* flex-direction: column; */
  }
  
  .search-box input
  {
   border:1px solid blue;
   width:500px;

  }
  .search_btn
  {
    width:120px;
    height:50px;
    background:#7b7c7e;
    color:#fff;
    border:none;
  }
  .search_btn i
  {
    font-size:22px;
    font-weight:800;

  }
  .count h2{
    border: 1px solid blue;
    font-size:16px;
    padding:15px;
    height:50px;
  }
  .count input{
    width:200px;
    margin-left:10px;
  }
  .formTitleSection
  {
    display:flex;
    justify-content: end;
  }
  .closeProgram1 
  {
    width:30px;
    height:30px;
   display:flex;
   justify-content:center;
   align-items:center;
    
    border-radius:5px;
    background:#c0c0c0;
    
  }
  .closeProgram1 i{
    display:flex;
   justify-content:center;
   align-items:center;
    font-size:20px;
    color:#fff;
  }
  .projSwiper
{ height:200px;
    min-height:100%;

}
.projSwiper img
{height:100%;
    width:100%;

}


.hidden_pro { display: none; }
.landing_products_banner .banner01 {background: url(../images/pbanner.jpg) no-repeat center center; background-size: cover;}
.landing_products_banner .banner_msg {margin-top: 0;}
.products_about {padding: 120px 0;}
.products_search_main {padding: 57px 0 47px;}
.products_search_type { border-radius:5px; border:2px dotted #fff; padding:10px;width:100px; height:100px;}
.products_search_type img {width:100%;
  height:100%;position: relative; z-index: 999;}
.products_search_desc{padding-left: 0;margin-top:10px;padding-top:15px; }
.products_search_desc h4 {font-family: 'Roboto',sans-serif; font-size: 1.2rem; line-height: 17px;}
.products_search_desc h4 a {color: #231f20;text-decoration: none;}
.products_search_type .hexagon {background: #231f20; width: 84%; height: 100%; font-size: 0; z-index: 99;}
.products_search_type .hexagoninner{background: #fecf0c; width: 96%; height: 96%; font-size: 0; transform: translate(-50%,-50%);z-index: 999;}
.productsearch { padding: 0 2.6%; margin-top: 40px; }
.productsearch input {height: 44px; font-family: 'Gotham-Book'; font-size: 13px; line-height: 18px; padding-top: 10px; display: inline-block; color: #606060;  border: 0; padding: 5px 0 0 50px; position: relative;}
.productsearch .ic_search {content: ''; position: absolute; left: 26px; top: 50%; transform: translateY(-50%); display: inline-block; width: 15px; height: 16px; 
  /* background:url(../images/products/ic_search.png) no-repeat center center;  */
  background-size: cover; z-index: 999;}
  .bgyellow { background:url('../images/product/productback.jpg') !important;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: cover;
  }
  .hexagon {clip-path: polygon(50% 0, 100% 28%, 100% 72%, 50% 100%, 0% 72%, 0% 28%);}
  .products_search_type .hexagoninner {
    background: #fecf0c;
    width: 96%;
    height: 96%;
    font-size: 0;
    transform: translate(-50%,-50%);
    z-index: 999;
}
.ButtonG
{
  color:#fff;
  border:1px solid #fff;
  
}

