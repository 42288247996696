/*----------------------------------------------------------------
					   Start Upperbar
----------------------------------------------------------------*/
.navbar-collapse
    {
        flex-grow:0;
    }
.upper-bar {
    background: #ffffff;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    border-bottom: 1px solid #f1f1f1;
}
.upper-bar-dark {
    background: #02185a;
}
.upper-bar .contact-us-bar {
    font-weight: 500;
}
.upper-bar .contact-us-bar p {
    line-height: 50px;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}
.upper-bar .contact-us-bar a {
    font-size: 12px;
    margin-right: 12px;
    color: #02185a;
    font-weight: 500;
    border-right: 2px solid #ccc;
    padding-right: 12px;
    transition: all 0.4s;
}
.upper-bar .contact-us-bar a:last-child {
    border-right: none;
}
.upper-bar .contact-us-bar a span {
    font-size: 14px;
    margin-right: 5px;
    transition: .1s;
    color: #ff6119;
}
.upper-bar .contact-us-bar a span::before {
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    font-weight: 900;
}
.upper-bar .contact-us-bar a:hover {
    color: #ff6119;
}
.upper-bar .social-media-bar {
    float: right;
    margin-bottom: 0;
    padding-left: 0;
}
.upper-bar .social-media-bar li {
    display: inline-block;
    padding-left: 3px;
}
.upper-bar .social-media-bar li a {
    transition: .4s;
    width: 30px;
    height: 30px;
    border-radius: 0px;
    background: #f9f9f9;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #02185a;
    display: block;
    border: 1px solid #f1f1f1;
}
.upper-bar .social-media-bar li a:hover {
    background: #ff6119;
    border: 1px solid #ff6119;
    color: #fff;
}

.upper-bar-dark .social-media-bar li a {
    transition: .4s;
    width: 30px;
    height: 30px;
    border-radius: 0px;
    background: transparent;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    display: block;
    border: 1px solid transparent;
}
.upper-bar-two {
    background: #fff;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    border-bottom: 1px solid #f1f1f1;
}
.upper-bar-two .contact-us-bar a {
    color: #02185a;
    font-weight: 600;
}
.upper-bar-two .contact-us-bar a span {
    color: #ff6119;
}
.upper-bar-two .social-media-bar li a {
    border: 1px solid #f1f1f1;
    background: #f9f9f9;
    color: #02185a;
}
.upper-bar-two .social-media-bar li a:hover {
    background: #ff6119;
    border: 1px solid #ff6119;
    color: #ffffff;
}
.classic-upper-bar {
    background: #ff6119;
}
.classic-upper-bar .contact-us-bar a {
    font-size: 12px;
    margin-right: 12px;
    color: #eee;
    font-weight: 500;
    border-right: 2px solid #eeeeee47;
    padding-right: 12px;
    transition: all 0.4s;
}
.classic-upper-bar .contact-us-bar a:hover {
    color: #FFF;
}
.classic-upper-bar .contact-us-bar a span {
    font-size: 14px;
    margin-right: 5px;
    transition: .1s;
    color: #eee;
}

.classic-upper-bar .social-media-bar li a {
    transition: .4s;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background: transparent;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #eee;
    display: block;
    border: 1px solid transparent;
}

.upper-bar-three {
    background: #02185a;
    z-index: 777;
    position: absolute;
    width: 100%;
    border-bottom: none;
}
.upper-bar-three .contact-us-bar a {
    color: #ddd;
    font-weight: 500;
    border-right: 2px solid rgba(204, 204, 204, 0.28);
}
.upper-bar-three .contact-us-bar a span {
    color: #fff;
    
}
.upper-bar-three .social-media-bar li a {
    border: 1px solid transparent;
    background: transparent;
    color: #FFF;
}
/*----------------------------------------------------------------
					   End Upperbar
----------------------------------------------------------------*/


/*----------------------------------------------------------------
					Start Navbar 
----------------------------------------------------------------*/  
nav {
    display: block;
    width: 100%;
    position: absolute;
    padding:0px;
    z-index: 999;
}
.stuck {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 5555;
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
    border-bottom: 1px solid #eee;
    background: #FFF;
}

.container-nav {
    position: relative;
}

.nav-menu-bar {
    background: #FFF;
    border-top: 1px solid #eee;
    height: 85px;
}
.my-logo { 
    
    /* width: 50px; */
    height:60px;
    
}
.my-logo img {
    display: inline-block;
    width: 100%;
    height:100%;
    /* transform:scale(1.3,1.2); */
    filter:brightness(45);
    
}
 /* .single-item {
    position: relative;
    padding-left: 50px;
    margin-top: 22px;
    float: right;
    width: 100%;
} */
.nav-bar .single-item {
    position: relative;
    padding-left: 60px;
    margin-bottom: 15px;
    margin-left: 0;
    margin-top: 15px;
}
.nav-bar .single-item i {
    left: 0;
    top: 20%;
    color: #02185a;
    position: absolute;
    font-size: 25px;
    width:40px;
    height:40px;
    border:1px solid #02185a;
    display:flex;
    justify-content: center;
    align-items:center;
    border-radius: 5px;
}
.nav-bar .single-item i::before {
    margin: 0;
    font-size: 25px;
    line-height: 35px;
}
.nav-bar .single-item p {
    font-size: 14px;
    color: #02185a;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 10px;
}
.nav-bar .single-item  span {
    left: 0;
    top: 0;
    color: #ff6119;
    position: absolute;
    font-size: 30px;
}
.nav-bar .single-item  span::before {
    margin: 0;
    font-size: 30px;
    line-height: 30px;
}
.nav-bar .single-item h3 {
    color: #02185a;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
}
.nav-bar .single-item p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 26px;
    color: #777777;
    font-weight: 500;
}
.nav-bar .btn-arrow-two {
    float: right;
    margin-top: 18px;
}
.navbar-toggle {
    height: 32px;
    line-height: 32px;
    width: 30px;
    cursor: pointer;
    top: 35px;
    right: 15px;
    position: absolute;
    display: none;
    
}
.navbar-toggle span {
    -webkit-transition: 0.4s ease-in-out;
    -moz-transition: 0.4s ease-in-out;
    -ms-transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
}
.navbar-toggle:hover span {
    background: #ff6119;
}
.navbar-toggle span:first-child {
    background: #02185a;
    display: block;
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 22px;
    top: 0;
    
}

.navbar-toggle span:nth-child(2) {
    background: #02185a;
    display: block;
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 22px;
    top: 6px;
}
.navbar-toggle span:nth-child(3) {
    background: #02185a;
    display: block;
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 22px;
    top: 12px;
}
.navbar-toggle-active span:first-child {
    top: 7px;
    transform: rotate(45deg);

    background: #ff6119;
}
.navbar-toggle-active span:nth-child(2) {
    display: none;
}
.navbar-toggle-active span:nth-child(3) {
    top: 7px;
    transform: rotate(135deg);
    background: #ff6119;
}



.nav-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    float: right;

    
}
.NavBar {
    /* border: 1px solid blue; */
    border-radius:0;
    /* background-color: #30d2b9; */
}

.NavBar-bg {
    background-color: #4a5e81;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.nav-menu li {
    display: inline-block;
}
.nav-link {
    color: #fff !important;
    text-decoration: none;
    display: block;
    margin-right: 28px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content:flex-end;
    align-items:flex-start;

    height:35px;
    /* padding:10px; */
    /* width:150px; */
    /* padding: 20px 0; */
    font-family: 'Poppins', sans-serif;
    position: relative;
    text-transform: uppercase;
    
}

@media(max-width:500px) 
{
    .navbar
    {position: relative;
        position: fixed;
        /* background: #fff; */
    }
    .nav-link
    {
        
        color:#ffff !important;
    }
    .my-logo { 
    
        /* width: 50px; */
        height:50px;
        
    }
    .my-logo img {
        display: inline-block;
        width: 100%;
        height:100%;
        transform:scale(1.3,1.2);
        filter:brightness(45);
        
    }
    .navbar-toggler{
        background-color: #ffff;
    }
    .navbar-collapse{
        background-color: #4a5e81;
        padding-bottom: 20px;
    }
}

.nav-link:hover{
    color:#fff;
}
 /* .nav-link,
.nav-menu .nav-item:hover .nav-link {
    color: #ff6119;
} */
/* .nav-link a:hover
{
    width:120px;
    height:30px;
    border:1px solid #000;
    display:flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms linear;
} */

 /* .nav-link:before {
    position: absolute;
    content: '';
    width: 100%;
    
    display: table;
    height: 3px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    bottom: 25px;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    border:1px solid #ff6119 !important;
    opacity: 0;
} */

#nav .active {
    border:1px solid #fff;
    font-weight: bold;
    border-radius: 10px;
    color:#fff;
padding-top:0;
}

/* .active a {
    color: #fff;
} */
/* .nav-menu .nav-item.active > .nav-link:before,
.nav-link:hover  .nav-link:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
    color: #0b2653 !important;
    background-color: #000;
} */

.has-dropdown .icon-down {
        opacity: 0.8;
    position: absolute;
    right: -12px;
    top: 31px;
}



.icon-links {
    float: right;
    margin-right: 40px;
    position: relative;
    top: 0;
    right: 0;
    margin-left: 35px;
}
.icon-links a {
    color: #02185a;
    text-decoration: none;
    display: inline-block;
    margin-left: 14px;
    font-size: 15px;
    font-weight: 700;
    padding: 30px 0;
    font-family: 'Roboto', sans-serif;
    position: relative;
    text-transform: uppercase;
    transition: all 0.4s;
}
.icon-links a:first-child {
    margin-left: 0;
}
.icon-links a:hover {
    color: #ff6119;
}

.nav-bar .nav-menu .nav-item.has-dropdown > ul.sub-menu {
    -webkit-transform: translateY(10%);
    -moz-transform: translateY(10%);
    -ms-transform: translateY(10%);
    -o-transform: translateY(10%);
    transform: translateY(10%);
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 230px;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 3px solid #ff6119;
    margin-left: 0px;
}
.nav-bar .nav-menu .nav-item.has-dropdown > ul.sub-menu li.active a {
    color: #ff6119;
}
.nav-bar .nav-menu .nav-item.has-dropdown > ul.sub-menu::after {
    clear: both;
    content: " ";
    display: block;
    width: 100%;
}
.nav-item.has-dropdown > ul.sub-menu li {
  display: block;
  z-index: 3;
}
.nav-item.has-dropdown > ul.sub-menu li a {
    opacity: .7;
    display: block;
    text-transform: capitalize;
    color: #3e3a3a;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 23px;
    margin: 0;
    position: relative;
    background: transparent;
    border-bottom: 1px solid #f1f1f1 !important;
    transition: .5s;
}
.nav-item.has-dropdown > ul.sub-menu li a:last-child {
    border: none;
}
.nav-item.has-dropdown > ul.sub-menu li a:hover {
    opacity: 1;
}
.nav-item.has-dropdown > ul.sub-menu li a:hover {
    color: #02185a;
    opacity: 1;
    background: #f9f9f9;
    padding-right: 20px;
}
.nav-item.has-dropdown:hover ul.sub-menu {
    -webkit-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    -o-transform: translateY(0) !important;
    transform: translateY(0) !important;
    opacity: 1 !important;
    visibility: visible !important;
 
}
.nav-item.has-dropdown > ul.sub-menu li {
    position: relative;
}
.nav-bar .sub-menu .has-dropdown-two {
    position: relative;
}
.nav-bar .sub-menu .has-dropdown-two span {
    float: right;
    margin-top: 4px;
    font-size: 12px;
}
.nav-bar .sub-menu .has-dropdown-two:hover > ul.sub-menu-two {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible
}
.nav-bar .sub-menu .has-dropdown-two > ul.sub-menu-two {
    -webkit-transform: translateY(10%);
    -moz-transform: translateY(10%);
    -ms-transform: translateY(10%);
    -o-transform: translateY(10%);
    transform: translateY(10%);
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 230px;
    visibility: hidden;
    opacity: 0;
    top: 0;
    background: #fff;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 3px solid #ff6119;
    margin-left: 230px;
}
.nav-bar .sub-menu .has-dropdown-two > ul.sub-menu-two::after {
    clear: both;
    width: 100%;
    display: block;
}

.nav-bar-two {
    background: #FFF;
}
.nav-bar-two .nav-menu {
    float: right;
}
.nav-bar-two .nav-menu li a {
    margin-left: 30px;
    margin-right: 0;
}



.nav-bar-three {
    position: absolute;
    width: 100%;
    background: transparent;
    
}



.nav-bar-three .nav-menu {
    float: left;
}
.nav-bar-three .navbar-toggle span {
    background: #fff;
}
.nav-fixed-two  .navbar-toggle span {
    background: #02185a;
}
.nav-bar-three .nav-menu li a {
    margin-left: 28px;
    margin-right: 0;
    color: #FFF;    
}

.nav-bar-three .icon-links .btn-three {
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    position: relative;
    z-index: 2;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 0 5px;
    line-height: 45px;
    min-width: 140px;
    height: 45px;
    letter-spacing: 0.30px;
    -webkite-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    box-shadow: none;
    border-radius: 2px;
    margin-top: 20px;
}
.nav-bar-three .icon-links .btn-three:hover {
    color: #fff;
}
.nav-bar-three .side-menu-btn span {
    background-color: #fff;
}


.fixed-top .my-logo .logo-two {
    display: none;

}
.fixed-top .my-logo .logo-one {
    display: block;
}
.fixed-top-one .my-logo .logo-two {
    display: none;
}
.nav-fixed-two .my-logo .logo-one {
    display: none;
}

.fixed-top::after,
.nav-fixed:after,
.fixed-top .nav-output::after,
.nav-fixed .nav-output::after {
    clear: both;
    content: "";
    width: 100%;
    display: block;
}
.fixed-top {
    position: fixed;
    top: 50px;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
}
.nav-fixed {
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
}

.nav-fixed .my-logo .logo-two {
    display: block;
    height:50px;
    width:100px;
}

.nav-fixed .my-logo .logo-one {
    display: none;
}
.fixed-top .nav-menu li a,
.fixed-top .icon-links a {
    color: #FFF;
}
.nav-bar-fixed .nav-menu .nav-item .nav-link:before {
    bottom: 20px;
}
.nav-bar-fixed .nav-menu {
    float: left;
}
.nav-bar-fixed .nav-menu .nav-menu li a {

    margin-right: 28px;
}
.nav-bar-three .stuck {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 5555;
    box-shadow: none;
        border-bottom: 1px solid rgba(214, 212, 212, 0.27);
    background: transparent;
}
.nav-fixed-two .stuck {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 5555;
    box-shadow: none;
    border-bottom: 1px solid rgba(144, 139, 139, 0.37);
    background: #FFF
}
.nav-fixed-two .nav-menu li a {
    margin-left: 28px;
    margin-right: 0;
    color: #02185a;

}
.nav-fixed-two  .side-menu-btn span {

     background: #122b51 !important;

}
.nav-fixed-two .btn-three {
    background: #ff6119;
    color: #FFF;
}

.nav-fixed-two .icon-links .btn-three:hover {
    color: #fff;
    background: #02185a;
}
/*----------------------------------------------------------------
					End Navbar 
----------------------------------------------------------------*/ 
/*----------------------------------------------------------------
					   Start Slids Menu 
----------------------------------------------------------------*/

/* Side Menu Item */
.side-menu {
    position: fixed;
    top: 0;
    right: -430px;
    width: 430px;
    height: 100%;
    min-height: 100%;
    padding: 100px 40px 50px 50px;
    background: #fff;
    overflow: hidden;
    visibility: hidden;
    z-index: 9999;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-box-shadow: -11px 0px 13px rgba(0,0,0,.02);
    -moz-box-shadow: -11px 0px 13px rgba(0,0,0,.02);
    -o-box-shadow: -11px 0px 13px rgba(0,0,0,.02);
    box-shadow: -11px 0px 13px rgba(0,0,0,.02);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}
.side-menu.open {
    right: 0;
    visibility: visible;
}
.close-side-menu {
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 2px;
    background: #ff6119;
    z-index: 55;
    text-align: center;
    line-height: 40px;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.close-side-menu  span {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    line-height: 40px;
    color: #fff;
}

.close-side-menu:hover {
    background: #02185a;
    color: #fff;
}

.close-side-menu:hover span {
    color: #fff;
}

.about-side {
    margin-bottom: 50px;
}
.about-side img {
    width: 150px;
    margin-bottom: 20px;
}
.about-side p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
}

.contact-side h6,
.follow-us h6 {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #02185a;
    letter-spacing: .5px;
    margin-bottom: 30px;
}
.contact-side .contact-info .single-contact  {
    margin-bottom: 25px;
}
.contact-side .contact-info .single-contact span {
    color: #ff6119;
}
.contact-side .contact-info .single-contact span::before {
    margin: 0;
}
.contact-side .contact-info .single-contact .info-cont p {
    color: #989898;
    font-weight: 600;
}
.contact-side {
    margin-bottom: 50px;
}
.side-menu .social-media li a {
    background: #f9f9f9;
    border: 1px solid #f1f1f1;
    color: #ff6119;
}

.side-menu .social-media li a:hover {
    background: #ff6119;
    border: 1px solid #ff6119;
    color: #fff;
}
/* Overlay Side Menu */
.close-menu-sidebar {
    height: 100%;
    background: rgba(253, 253, 253, 0.95);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9998;
    /* cursor: url("../images/icons/cancel.png"), auto; */
    opacity: 0;
    visibility: hidden;
    width: 0px;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}
.close-menu-sidebar.open {
    width: 100%;
    opacity: 1;
    visibility: inherit;
}

.side-menu-btn{
        text-decoration: none;
    font-family: 'Poppins', sans-serif;
    transition: all .4s;
    border-radius: 0;
    color: #122b51 !important;
    text-align: center;
    padding: 0;
    right: 0;
    z-index: 555;
    width: 20px;
    height: 15px;
    position: absolute !important;
    right: -40px;
    top: 35px;
}
.side-menu-btn:hover{
    color:#3333ff !important;
} 
.side-menu-btn span {
    display: block;
    position: absolute;
    background-color: #0b2653;
    height: 2.3px;
    -webkit-transition: all .3s linear 0ms;
    -khtml-transition: all .3s linear 0ms;
    -moz-transition: all .3s linear 0ms;
    -ms-transition: all .3s linear 0ms;
    -o-transition: all .3s linear 0ms;
    transition: all .3s linear 0ms;
    width: 20px;
}

.side-menu-btn span:first-child {
    top: 0;
    right: 0;
}
.side-menu-btn span:nth-child(2) {
    top: 6px;
    right: 0;
    width: 16px;
}
.side-menu-btn span:last-child {
    top: 12px;
    left: 0;
}
.side-menu-btn:hover span:nth-child(2) {
     width: 20px;
}
/* ============================================ Equary Modal ============================== */
#partner {
    width: 100%;
    z-index: 1000;
    flex-direction: column;
  }
  .programButton {
    overflow: hidden;
    cursor: pointer;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .partnerProgramForm {
    padding: 20px;
    display: flex;
    justify-content:end;
    /* align-items: center; */
    flex-direction: column;
    position: relative;
    z-index: 1000;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
    animation: uparrow 0.6s infinite alternate ease-in-out;
  }
  
  .partnerProgramForm img{
    width: 100%;
    height:100%;
  }